import { useState, useContext, useEffect, useCallback } from "react";
import { doc, getFirestore, setDoc, onSnapshot } from "firebase/firestore";
import firebaseApp from "../Firebase/firebase";
import { UserContext } from "../Authentication/UserContext";
import { getIpAddress, getVirtualMachineUserPassword } from "../api";
import { RDP_CONNECTION_STATE } from "../Components/RdpConnectionState";

export default function useRemoteDesktopConnection(subscriptionId: string, vmId: string) {
    const { user } = useContext(UserContext);
    const [rdp, setRdp] = useState<RdpModel>({state: RDP_CONNECTION_STATE.DISABLED});
    const [rdpPwd, setRdpPwd] = useState<string | null>(null);
    const [rdpPwdShown, setRdpPwdShown] = useState<boolean>(false);
    const [ipIsValid, setIpIsValid] = useState(true)
    const [isPwdCopied, setIsPwdCopied] = useState(false);

    const firestore = getFirestore(firebaseApp);

    const getRdpDocRef = useCallback((userid:string, subscriptionId:string) => {
        const docPath = `customers/${userid}/rdp-connections/${subscriptionId}`;
        return doc(firestore, docPath);
    }, [firestore]);

    useEffect(() => {
        if (!user) return; 
        const docRef = getRdpDocRef(user.id, subscriptionId);
        const unsubscribe = onSnapshot(docRef, (snapshot) => {
            if (snapshot.exists()) {
                const docData = snapshot.data();
                setRdp({state: docData.state.toLowerCase() , allowedIpAddress: docData.allowedIpAddress, port: docData.port, lastError: docData.lastError})
            } else {
                setRdp({state: RDP_CONNECTION_STATE.DISABLED})
            }
        });

        return () => unsubscribe();
    },[user, subscriptionId, getRdpDocRef])


    async function togglePasswordShow(show: boolean) {
        if (!user) return;
        if (show) {
            const password = await getVirtualMachineUserPassword(user?.token, vmId);
            setRdpPwd(password);
            setRdpPwdShown(true);
        } else
        {
            setRdpPwdShown(false)
        }
    }

     const toggleRdp = async (enable: boolean) => {
        if (!user) return;
        
        const rdpDocRef = getRdpDocRef(user.id, subscriptionId);
        if (enable) {
            const ip = await getIpAddress();
            await setDoc(rdpDocRef, {
                vmId,
                userId: user.id,
                subscriptionId,
                allowedIpAddress: ip,
                state: RDP_CONNECTION_STATE.ENABLING
            });
        } else {
            await setDoc(rdpDocRef, {
                vmId,
                userId: user.id,
                subscriptionId,
                state: RDP_CONNECTION_STATE.DISABLING
            });
        }
    };

    async function copyRdpPwd() {
        if (!user) return;
        const password = await getVirtualMachineUserPassword(user?.token, vmId);
        navigator.clipboard.writeText(password);
        setIsPwdCopied(true);
        setTimeout(() => {
            setIsPwdCopied(false);
        }, 2000);
        
    };

    async function handleRdpIpBlur(e: any) {
        if (!user) return;

        const ipAddress = e.target.value;
        const regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        const isValid = regex.test(ipAddress);

        if (!isValid) {
            setIpIsValid(false);
            return;
        } 
        
        const docRef = getRdpDocRef(user.id, subscriptionId);

        await setDoc(docRef, {
            allowedIpAddress: ipAddress
        }, {merge: true});

        setIpIsValid(true);
    }

    return {rdp, rdpPwd, rdpPwdShown, ipIsValid, isPwdCopied, togglePasswordShow, setRdpPwd, toggleRdp, copyRdpPwd, handleRdpIpBlur}
}

export type RemoteDesktopConnectionProps = {
    subscriptionId: string,
    vmId: string
};

export type RdpModel = {
    allowedIpAddress?: string | undefined,
    port?: string | undefined,
    state: string,
    lastError?: Date | undefined
}